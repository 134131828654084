import DropdownList from 'react-widgets/DropdownList';
import 'react-widgets/styles.css';
import styles from './Combobox.module.scss';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';

const Combobox = ({
  id,
  name,
  items,
  value: initialValue,
  className,
  onChange,
  onClick,
  placeholderText,
  filterType = 'contains', // "startsWith" "eq" "contains"
}) => {
  return (
    <DropdownList
      id={id}
      name={name}
      className={joinClasses(styles.select, styles.wide, className)}
      defaultValue={initialValue && items.map(item => item.value).includes(initialValue) ? initialValue : (items.length ? items[0].value : '')}
      placeholder={placeholderText}
      data={items}
      dataKey={'value'}
      textField={'name'}
      onChange={value => onChange(value)}
      onClick={() => onClick()}
      filter={filterType}
    />
  );
};

Combobox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholderText: PropTypes.string,
  filterType: PropTypes.string,
};

export default Combobox;